/**
 * Nosy Team Member
 *
 * @export Team
 */
import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    Text,
    Tooltip,
    useBreakpointValue
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import { PortableText } from '@portabletext/react'
import { graphql } from 'gatsby'
import React from 'react'
import BackLink from '../../components/back-link/back-link'
import FeaturedLink from '../../components/featured-link/featured-link'
import ForwardArrow from '../../components/foward-arrow/forward-arrow'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import SEO from '../../components/seo/seo'
import SocialIcons from '../../components/social-icons/social-icons'
import { defaultSerializer, excerptSerializer } from '../../serializers'
import { mapEdgesToNodes, pluckRandomNodes } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_TEAM_QUERY($id: String!) {
        sanityTeam(id: { eq: $id }) {
            id
            name
            role
            mainImage {
                ...ImageWithPreview
            }
            bio: _rawBio(resolveReferences: { maxDepth: 10 })
            socialUrls: _rawSocialUrl(resolveReferences: { maxDepth: 10 })
            teamExternalWork: _rawTeamExternalWork(
                resolveReferences: { maxDepth: 10 }
            )
            slug {
                current
            }
        }
        allSanityWork(
            filter: {
                team: { elemMatch: { id: { eq: $id } } }
                _id: { regex: "/^(?!drafts).*/" }
            }
            limit: 4
        ) {
            nodes {
                id
                title
                heading
                slug {
                    _key
                    _type
                    current
                }
                featureImage {
                    ...ImageWithPreview
                }
            }
        }
        allSanityTeam(
            filter: { 
                id: { 
                    ne: $id 
                } 
                isCurrent: {
                    eq: true
                }
            }
        ) {
            edges {
                node {
                    id
                    name
                    role
                    mainImage {
                        ...ImageWithPreview
                    }
                    slug {
                        current
                    }
                }
            }
        }
    }
`

const Project = ( props ) => {
    // console.log( '🦄 ~ file: tean.js ~ line 64 ~ Project ~ props', { props } )

    const {
        data: {
            sanityTeam: {
                name = '',
                role = '',
                mainImage = {},
                bio = [],
                socialUrls = [],
                teamExternalWork = [],
                slug: { current: url = '' }
            },
            allSanityWork: { nodes: workProjects = [] },
            allSanityTeam = []
        }
    } = props

    // Map edges to node item
    const team = mapEdgesToNodes( allSanityTeam )

    // Pluck 3 random team members
    const randomTeamMembers = pluckRandomNodes( team, 3 )

    // Destructure first block of bio for decsription
    const [
        {
            children: [{ text: bioExcerpt = '' }]
        }
    ] = bio

    const teamMemberImageWidths = useBreakpointValue( {
        base: 447,
        sm: 735,
        md: 432,
        lg: 512
    } )

    const projectImageWidths = useBreakpointValue( {
        base: 447,
        sm: 735,
        md: 170
    } )

    const teamMembersImageWidths = useBreakpointValue( {
        base: 447,
        sm: 344,
        md: 267,
        lg: 320
    } )

    return (
        <Layout>
            <SEO
                title={`${name} | ${role} | Team`}
                description={bioExcerpt}
                image={mainImage}
                url={`/team/${url}/`} />
            <Container maxWidth='6xl' mb={[20, 40]}>
                <Tooltip
                    label='See all team'
                    aria-label='See all team'
                    fontSize='md'
                    placement='top'
                    backgroundColor='brand_primary.700'
                    color='white'
                    arrowShadowColor='brand_primary.700'>
                    <Box as='span' width='32px' height='32px' display='block'>
                        <BackLink to='/team/' />
                    </Box>
                </Tooltip>
                <Stack
                    direction={['column-reverse', null, 'row']}
                    spacing={[10, null, 24]}>
                    <Stack direction='column' width={['full', null, '50%']} spacing={12}>
                        <Stack
                            direction='column'
                            spacing={[0, null, 10]}
                            mt={[0, null, 6]}>
                            <Stack
                                direction='column'
                                spacing={2}
                                display={['none', null, 'block']}>
                                <Heading
                                    as='h1'
                                    fontSize={['4xl', '5xl', '7xl']}
                                    fontWeight='bold'
                                    lineHeight='none'
                                    color='white'>
                                    {name}
                                </Heading>
                                <Heading
                                    as='h2'
                                    fontSize={['2xl', '3xl', '4xl']}
                                    fontWeight='bold'
                                    color={transparentize( 'gray.600', 0.8 )}
                                    lineHeight='none'>
                                    {role}
                                </Heading>
                            </Stack>
                            {bio && (
                                <PortableText
                                    value={bio}
                                    components={defaultSerializer} />
                            )}
                        </Stack>
                        <SocialIcons platforms={socialUrls} />
                    </Stack>
                    <Stack direction='column' width={['full', null, '50%']}>
                        <Stack
                            direction='column'
                            spacing={2}
                            display={['block', null, 'none']}
                            my={6}>
                            <Heading
                                as='h1'
                                fontSize={['4xl', '5xl', '7xl']}
                                fontWeight='bold'
                                lineHeight='none'
                                color='white'>
                                {name}
                            </Heading>
                            <Heading
                                as='h2'
                                fontSize={['2xl', '3xl', '4xl']}
                                fontWeight='bold'
                                color={transparentize( 'gray.600', 0.8 )}
                                lineHeight='none'>
                                {role}
                            </Heading>
                        </Stack>
                        <Image
                            imageData={mainImage}
                            width={teamMemberImageWidths}
                            height={550}
                            mt={[10, 0]}
                            position='sticky'
                            top='217px'
                            sx={{
                                width: 'full'
                            }} />
                    </Stack>
                </Stack>
            </Container>

            <Container maxWidth='6xl' mb={[20, 40]}>
                {workProjects.length > 0 && (
                    <Stack direction='column' spacing={6}>
                        <Heading as='h2' color='brand_secondary.500'>
                            Projects
                        </Heading>

                        <SimpleGrid columns={[1, null, 2]} spacing={10} my={20}>
                            {workProjects.map( ( workProject ) => (
                                <LinkBox 
                                    key={workProject.id}
                                    role='group'>
                                    <Stack
                                        direction={['column', null, 'row']}
                                        spacing={10}
                                        borderTop='1px'
                                        borderColor={transparentize(
                                            'gray.600',
                                            0.8
                                        )}
                                        pt={10}
                                        mb={4}>
                                        <Box height='full' minWidth='170px'>
                                            <Image
                                                imageData={
                                                    workProject.featureImage
                                                }
                                                width={projectImageWidths}
                                                height={170}
                                                objectFit='cover'
                                                sx={{
                                                    width: 'full'
                                                }} />
                                        </Box>
                                        <Stack
                                            direction='column'
                                            height='full'
                                            textDecoration='none'
                                            spacing={6}>
                                            <LinkOverlay
                                                as={Link}
                                                to={`/work/${workProject.slug.current}/`}
                                                _hover={{
                                                    color: 'brand_secondary.500'
                                                }}>
                                                <Heading
                                                    as='h4'
                                                    fontWeight='medium'
                                                    fontSize='2xl'
                                                    color='gray.100'
                                                    transition='all 0.343s'
                                                    _groupHover={{
                                                        color: 'brand_secondary.500'
                                                    }}>
                                                    {workProject.title}
                                                </Heading>
                                            </LinkOverlay>
                                            <Box width='77%'>
                                                <Text color='gray.400'>
                                                    {workProject.heading}
                                                </Text>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </LinkBox>
                            ) )}
                        </SimpleGrid>
                    </Stack>
                )}
            </Container>

            <Container maxWidth='6xl' mb={[20, 40]}>
                {teamExternalWork && teamExternalWork.length > 0 && (
                    <Stack direction='column' spacing={6}>
                        <Heading as='h2' color='brand_secondary.500'>
                            More {name.split( ' ' )[0]}
                        </Heading>

                        <SimpleGrid columns={[1, null, 2]} spacing={10} my={20}>
                            {teamExternalWork &&
                                teamExternalWork.map( ( externalWork ) => (
                                    <LinkBox key={externalWork._key}>
                                        <Stack
                                            spacing={6}
                                            direction='column'
                                            borderTop='1px'
                                            borderColor={transparentize(
                                                'gray.600',
                                                0.8
                                            )}
                                            pt={10}>
                                            <FeaturedLink
                                                to={externalWork.url}
                                                target='_blank'
                                                label={externalWork.name} />
                                            {externalWork.mainImage.asset && (
                                                <Image
                                                    imageData={
                                                        externalWork.mainImage
                                                    }
                                                    width={475}
                                                    height={220}
                                                    sx={{
                                                        width: 'full'
                                                    }} />
                                            )}
                                            <Box width='77%'>
                                                <PortableText
                                                    value={externalWork.excerpt}
                                                    components={
                                                        excerptSerializer
                                                    } />
                                            </Box>
                                        </Stack>
                                    </LinkBox>
                                ) )}
                        </SimpleGrid>
                    </Stack>
                )}
            </Container>

            <Container maxWidth='6xl'>
                <Stack direction='column' spacing={8}>
                    <SimpleGrid columns={[1, 2, 3]} spacing={[8, 12, 20]}>
                        {randomTeamMembers.map( ( { node }, index ) => (
                            <LinkBox key={node.id}>
                                <Box
                                    position='relative'
                                    mt={() => {
                                        const marginMap = {
                                            0: 60,
                                            1: 0,
                                            2: 36
                                        }

                                        return [4, 8, marginMap[index]]
                                    }}
                                    role='group'>
                                    <Image
                                        imageData={node.mainImage}
                                        width={teamMembersImageWidths}
                                        height={480}
                                        sx={{
                                            width: 'full'
                                        }} />
                                    <LinkOverlay
                                        as={Link}
                                        to={`/team/${node.slug.current}/`}>
                                        <Box
                                            px={8}
                                            pt={20}
                                            pb={4}
                                            visibility='hidden'
                                            transition='all .343s'
                                            width='full'
                                            bgGradient='linear(to-b, transparent, brand_grayscale.darker)'
                                            position='absolute'
                                            bottom={0}
                                            opacity={0}
                                            _groupHover={{
                                                visibility: 'visible',
                                                opacity: 1
                                            }}>
                                            <Stack
                                                direction='column'
                                                spacing={2}
                                                opacity={0}
                                                transform='translateY(31px)'
                                                transition='all .343s'
                                                transitionDelay='.25s'
                                                _groupHover={{
                                                    transform: 'translateY(0)',
                                                    opacity: 1
                                                }}>
                                                <Heading
                                                    as='h4'
                                                    color='brand_secondary.500'
                                                    fontSize={[
                                                        'lg',
                                                        'xl',
                                                        '2xl'
                                                    ]}>
                                                    {node.name}
                                                </Heading>
                                                <Stack
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    alignItems='center'>
                                                    <Text color='gray.100'>{node.role}</Text>
                                                    <ForwardArrow
                                                        color='brand_secondary.500'
                                                        transition='all 0.343s'
                                                        width='14px'
                                                        height='16px' />
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </LinkOverlay>
                                </Box>
                            </LinkBox>
                        ) )}
                    </SimpleGrid>

                    <Center>
                        <Button
                            as={Link}
                            to='/team/'
                            size='lg'
                            variant='brand-outline'>
                            Meet the team
                        </Button>
                    </Center>
                </Stack>
            </Container>
        </Layout>
    )
}

export default Project
